import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices

import actuarialTypeReducer from './slices/actuarialType';
import ageGroupReducer from './slices/ageGroup';
import genderReducer from './slices/gender';
import healthServiceGroupReducer from './slices/healthServiceGroup';
import populationGroupReducer from './slices/populationGroup';
import serviceFileReducer from './slices/serviceFile'
import healthCaseReducer from './slices/healthCase'
import populationDistributionReducer from './slices/populationDistribution'
import combinationServiceReducer from './slices/combinationService'
import combinationSocGroupReducer from './slices/combinationSocGroup'
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// PersistConfig
export const serviceFilePersistConfig = {
  key: 'serviceFile',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const healthCasePersistConfig = {
  key: 'healthCase',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const populationDistributionPersistConfig = {
  key: 'populationDistribution',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const combinationServicePersistConfig = {
  key: 'combinationService',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const combinationSocGroupPersistConfig = {
  key: 'combinationSocGroup',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const actuarialTypePersistConfig = {
  key: 'actuarialType',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
export const ageGroupPersistConfig = {
  key: 'ageGroup',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
export const genderPersistConfig = {
  key: 'gender',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const healthServiceGroupPersistConfig = {
  key: 'healthServiceGroup',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
export const populationGroupPersistConfig = {
  key: 'populationGroup',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  serviceFile: persistReducer(serviceFilePersistConfig, serviceFileReducer),
  healthCase: persistReducer(healthCasePersistConfig, healthCaseReducer),
  populationDistribution: persistReducer(populationDistributionPersistConfig, populationDistributionReducer),
  combinationService: persistReducer(combinationServicePersistConfig, combinationServiceReducer),
  combinationSocGroup: persistReducer(combinationSocGroupPersistConfig, combinationSocGroupReducer),
  actuarialType: persistReducer(actuarialTypePersistConfig, actuarialTypeReducer),
  ageGroup: persistReducer(ageGroupPersistConfig, ageGroupReducer),
  gender: persistReducer(genderPersistConfig, genderReducer),
  healthServiceGroup: persistReducer(healthServiceGroupPersistConfig, healthServiceGroupReducer),
  populationGroup: persistReducer(populationGroupPersistConfig, populationGroupReducer),
});

export default rootReducer;
