export const populationGroup = {
    list: {
        title: `Population groups`,
    },
    create: {
        title: `Create population group`,
    },
    update: {
        title: `Update population group`,
    },
    breadcrumbs: {
        base: `Population groups`,
        create: `Create`,
        update: `Update`,
    },
    fields: {
        name: `name`,
        code: `code`,
    },
    messages: {
        nameismandatory: `name is mandatory`,
        codeismandatory: `code is mandatory`,
    }
};