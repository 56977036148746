export const dashboardHomePage = {
    title: `Գլխավոր էջ`,
    welcome: `Բարի գալուստ`,
    welcome_note: `Դուք գտնվում եք առողջապահական ապահովագրության ծածկույթի հաշվարկման գործիքի միջավայրում`,
    slider1: {
        title: `Շտեմարաններ`,
        description: `Մշակվում են անհրաժեշտ շտեմարանները`
    },
    slider2:
    {
        title: `Ծառայությունների անձնագրեր`,
        description: `Հաշվարկվում է ծառայությունների արժեքները`
    },
    catalogs_info: {
        count: `Շտեմարանների քանակ`
    },
    services_info: {
        count: `Ծառայությունների քանակ`
    },
    test_info: {
        count: `Ինչ որ արժեք`
    }
};