import { general } from "./english/general";
import { menu } from "./english/menu";

import { front_page } from "./english/front-page";
import { dashboardHomePage } from "./english/dashboardHomePage";

import { serviceFile } from "./english/serviceFile"
import { healthCase } from "./english/healthCase"

import { actuarialType } from "./english/actuarialType";
import { ageGroup } from "./english/ageGroup";
import { gender } from "./english/gender";
import { healthServiceGroup } from "./english/healthServiceGroup";
import { populationGroup } from "./english/populationGroup";
import { populationDistribution } from "./english/populationDistribution";
import { combinationService } from "./english/combinationService";
import { combinationSocGroup } from "./english/combinationSocGroup";


const en = {
    general,
    menu,
    serviceFile,
    healthCase,
    actuarialType,
    ageGroup,
    gender,
    healthServiceGroup,
    populationGroup,
    front_page,
    dashboardHomePage,
    populationDistribution,
    combinationService,
    combinationSocGroup
};

export default en;
