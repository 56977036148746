import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
// import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
// import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  // LoginPage,
  // RegisterPage,
  // VerifyCodePage,
  // NewPasswordPage,
  // ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  Page500,
  Page403,
  Page404,
  HomePage,
  ComingSoonPage,
  MaintenancePage,
  // --------------------
  ServiceFileListPage,
  ServiceFileCreatePage,
  ServiceFileUpdatePage,
  // --------------------
  HealthCaseListPage,
  // HealthCaseCreatePage,
  // HealthCaseUpdatePage,
  // --------------------
  PopulationDistributionListPage,
  PopulationDistributionCreatePage,
  PopulationDistributionUpdatePage,
  PopulationDistributionImportPage,
  // --------------------
  CombinationServiceListPage,
  // --------------------
  CombinationSocGroupListPage,
  // --------------------
  ActuarialTypeListPage,
  ActuarialTypeCreatePage,
  ActuarialTypeUpdatePage,
  // --------------------
  AgeGroupListPage,
  AgeGroupCreatePage,
  AgeGroupUpdatePage,
  //
  GenderListPage,
  GenderCreatePage,
  GenderUpdatePage,
  //
  HealthServiceGroupListPage,
  HealthServiceGroupCreatePage,
  HealthServiceGroupUpdatePage,
  //
  PopulationGroupListPage,
  PopulationGroupCreatePage,
  PopulationGroupUpdatePage,
} from './elements';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    // {
    //   path: 'auth',
    //   children: [
    //     {
    //       path: 'login',
    //       element: (
    //         <GuestGuard>
    //           <LoginPage />
    //         </GuestGuard>
    //       ),
    //     },
    //     {
    //       path: 'register',
    //       element: (
    //         <GuestGuard>
    //           <RegisterPage />
    //         </GuestGuard>
    //       ),
    //     },
    //     { path: 'login-unprotected', element: <LoginPage /> },
    //     { path: 'register-unprotected', element: <RegisterPage /> },
    //     {
    //       element: <CompactLayout />,
    //       children: [
    //         { path: 'reset-password', element: <ResetPasswordPage /> },
    //         { path: 'new-password', element: <NewPasswordPage /> },
    //         { path: 'verify', element: <VerifyCodePage /> },
    //       ],
    //     },
    //   ],
    // },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        {
          path: 'serviceFiles',
          children: [
            { path: 'list', element: <ServiceFileListPage /> },
            { path: 'new', element: <ServiceFileCreatePage /> },
            { path: 'serviceFile/:id/edit', element: <ServiceFileUpdatePage /> },
          ]
        },
        {
          path: 'healthCases',
          children: [
            { path: 'list', element: <HealthCaseListPage /> },
            // { path: 'new', element: <HealthCaseCreatePage /> },
            // { path: 'healthCase/:id/edit', element: <HealthCaseUpdatePage /> },
          ]
        },
        {
          path: 'populationDistributions',
          children: [
            { path: 'list', element: <PopulationDistributionListPage /> },
            { path: 'new', element: <PopulationDistributionCreatePage /> },
            { path: 'import', element: <PopulationDistributionImportPage /> },
            { path: 'populationDistribution/:id/edit', element: <PopulationDistributionUpdatePage /> },
          ]
        },
        {
          path: 'combination/services',
          children: [
            { path: 'list', element: <CombinationServiceListPage /> },
          ]
        },
        {
          path: 'combination/socGroups',
          children: [
            { path: 'list', element: <CombinationSocGroupListPage /> },
          ]
        },
        {
          path: 'actuarialTypes',
          children: [
            { path: 'list', element: <ActuarialTypeListPage /> },
            { path: 'new', element: <ActuarialTypeCreatePage /> },
            { path: 'actuarialType/:id/edit', element: <ActuarialTypeUpdatePage /> },
          ]
        },
        {
          path: 'ageGroups',
          children: [
            { path: 'list', element: <AgeGroupListPage /> },
            { path: 'new', element: <AgeGroupCreatePage /> },
            { path: 'ageGroup/:id/edit', element: <AgeGroupUpdatePage /> },
          ]
        },
        {
          path: 'genders',
          children: [
            { path: 'list', element: <GenderListPage /> },
            { path: 'new', element: <GenderCreatePage /> },
            { path: 'gender/:id/edit', element: <GenderUpdatePage /> },
          ]
        },
        {
          path: 'healthServiceGroups',
          children: [
            { path: 'list', element: <HealthServiceGroupListPage /> },
            { path: 'new', element: <HealthServiceGroupCreatePage /> },
            { path: 'healthServiceGroup/:id/edit', element: <HealthServiceGroupUpdatePage /> },
          ]
        },
        {
          path: 'populationGroups',
          children: [
            { path: 'list', element: <PopulationGroupListPage /> },
            { path: 'new', element: <PopulationGroupCreatePage /> },
            { path: ' populationGroup/:id/edit', element: <PopulationGroupUpdatePage /> },
          ]
        },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
