export const ageGroup = {
    list: {
        title: `Տարիքային խմբեր`,
    },
    create: {
        title: `Ստեղծել տարիքային խումբ`,
    },
    update: {
        title: `Խմբագրել տարիքային խումբը`,
    },
    breadcrumbs: {
        base: `Տարիքային խմբեր`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
    },
    fields: {
        name: `անվանում`,
        code: `կոդ`,
    },
    messages: {
        nameismandatory: `անվանումը պարտադիր է`,
        codeismandatory: `կոդը պարտադիր է`,
    }
};