// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  serviceFiles: {
    root: path(ROOTS_DASHBOARD, '/serviceFiles'),
    list: path(ROOTS_DASHBOARD, '/serviceFiles/list'),
    new: path(ROOTS_DASHBOARD, '/serviceFiles/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/serviceFiles/serviceFile/${id}/edit`),
  },
  healthCases: {
    root: path(ROOTS_DASHBOARD, '/healthCases'),
    list: path(ROOTS_DASHBOARD, '/healthCases/list'),
    new: path(ROOTS_DASHBOARD, '/healthCases/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/healthCases/healthCase/${id}/edit`),
  },
  populationDistributions: {
    root: path(ROOTS_DASHBOARD, '/populationDistributions'),
    list: path(ROOTS_DASHBOARD, '/populationDistributions/list'),
    new: path(ROOTS_DASHBOARD, '/populationDistributions/new'),
    import: path(ROOTS_DASHBOARD, '/populationDistributions/import'),
    edit: (id) => path(ROOTS_DASHBOARD, `/populationDistributions/populationDistribution/${id}/edit`),
  },
  combination: {
    root: path(ROOTS_DASHBOARD, '/combination'),
  },
  combinationServices: {
    root: path(ROOTS_DASHBOARD, '/combination/services'),
    list: path(ROOTS_DASHBOARD, '/combination/services/list'),
  },
  combinationSocGroups: {
    root: path(ROOTS_DASHBOARD, '/combination/socGroups'),
    list: path(ROOTS_DASHBOARD, '/combination/socGroups/list'),
  },
  actuarialTypes: {
    root: path(ROOTS_DASHBOARD, '/actuarialTypes'),
    list: path(ROOTS_DASHBOARD, '/actuarialTypes/list'),
    new: path(ROOTS_DASHBOARD, '/actuarialTypes/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/actuarialTypes/actuarialType/${id}/edit`),
  },
  ageGroups: {
    root: path(ROOTS_DASHBOARD, '/ageGroups'),
    list: path(ROOTS_DASHBOARD, '/ageGroups/list'),
    new: path(ROOTS_DASHBOARD, '/ageGroups/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/ageGroups/ageGroup/${id}/edit`),
  },
  genders: {
    root: path(ROOTS_DASHBOARD, '/genders'),
    list: path(ROOTS_DASHBOARD, '/genders/list'),
    new: path(ROOTS_DASHBOARD, '/genders/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/genders/gender/${id}/edit`),
  },
  healthServiceGroups: {
    root: path(ROOTS_DASHBOARD, '/healthServiceGroups'),
    list: path(ROOTS_DASHBOARD, '/healthServiceGroups/list'),
    new: path(ROOTS_DASHBOARD, '/healthServiceGroups/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/healthServiceGroups/healthServiceGroup/${id}/edit`),
  },
  populationGroups: {
    root: path(ROOTS_DASHBOARD, '/populationGroups'),
    list: path(ROOTS_DASHBOARD, '/populationGroups/list'),
    new: path(ROOTS_DASHBOARD, '/populationGroups/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/populationGroups/populationGroup/${id}/edit`),
  },
  // ----------------------
};

export const PATH_DOCS = {
  // root: 'https://docs.minimals.cc',
  // changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
