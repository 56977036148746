export const healthCase = {
    list: {
        title: `Փաստացի դեպքեր`,
    },
    create: {
        title: `Ստեղծել նոր դեպք`,
    },
    update: {
        title: `Խմբագրել դեպքը`,
    },
    breadcrumbs: {
        base: `Փաստացի դեպքեր`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
    },
    fields: {
        caseNumber: `համար`,
        identificator: `նույնացուցիչ`,
        birthDate: `ծննդյան ա/թ`,
        gender: `սեռ`,
        dataOperator: `օպերատոր`,
        service: `ծառայություն`,
        serviceStartDate: `սկիզբ`,
        serviceCompletionDate: `ավարտ`,
    },
    messages: {
        getCurrentItemError: `Չհաջողվեց ստանալ նշված դեպքի տվյալները`,
        caseNumberismandatory: `համարը պարտադիր է`,
    }
};