export const populationDistribution = {
    list: {
        title: `Բնակչության բաշխում`,
    },
    create: {
        title: `Ստեղծել նոր խումբ`,
    },
    update: {
        title: `Խմբագրել խումբը`,
    },
    import: {
        title: `Ներմուծել խմբեր`,
    },
    breadcrumbs: {
        base: `Բնակչության բաշխում`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
        import: `Ներմուծել`,
    },
    fields: {
        genderName: `սեռ`,
        ageGroupName: `տարիքային խումբ`,
        populationGroupName: `բնակչության խումբ`,
        population: `բնակչություն`,
        year: `տարի`,
        removeBeforeImport: `հեռացնել մինչ ներմուծումը`
    },
    groups: {
        code01: `Աշխատողներ և ինքնազբաղվածներ`,
        code02: `Հաշմանդամներ`,
        code03: `Երեխաներ`,
        code04: `Այլ`,
        code05: `Թոշակառուներ (63+ տարիքի անձինք)`,
        code06: `Սոցանապահով և հատուկ խմբեր (ներառյալ զինվորականների ընտանիքի անդամներ)`
    },
    tabs: {
        dataExecution: `տվյալների մշակում`,
        summary: `ամփոփ`
    },
    messages: {
        getCurrentItemError: `Չհաջողվեց ստանալ նշված խմբի տվյալները`,
        genderIsRequired: `սեռը պարտադիր է`,
        ageGroupIsRequired: `տարիքային խումբը պարտադիր է`,
        populationGroupIsRequired: `բնակչության խումբը պարտադիր է`,
        populationIsRequired: `բնակչության թիվը պարտադիր է`,
        yearIsRequired: `տարին պարտադիր է`,
        getGengerDicError: `Չհաջողվեց ստանալ սեռերի բառարանը`,
        getAgeGroupDicError: `Չհաջողվեց ստանալ տարիքային խմբերի բառարանը`,
        getPopulationGroupDicError: `Չհաջողվեց ստանալ բնակչության խմբերի բառարանը`,
        fileismandatory: `ֆայլը պարտադիր է`,
    }
};