import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from "react-oidc-context"
// @mui
import { alpha } from '@mui/material/styles';
import { 
  Box, 
  Divider, 
  Typography, 
  // Stack, 
  MenuItem 
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// auth
// import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
// localization
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

// const OPTIONS = [
//   {
//     label: 'Home',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     linkTo: PATH_DASHBOARD.user.profile,
//   },
//   {
//     label: 'Settings',
//     linkTo: PATH_DASHBOARD.user.account,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  // const navigate = useNavigate();
  const { translate } = useLocales();

  // const { user, logout } = useAuthContext();
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      auth.signoutRedirect();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  // const handleClickItem = (path) => {
  //   handleClosePopover();
  //   navigate(path);
  // };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={auth.user?.photoURL} alt={auth.user?.profile.name} name={auth.user?.profile.name} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {/* <Typography variant="subtitle2" noWrap>
            {auth.user?.profile.name}
          </Typography> */}

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth.user?.profile.name}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('general.actions.logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
