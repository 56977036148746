import { createSlice } from '@reduxjs/toolkit';
import { pageParams } from '../../config-global'

const initialState = {
  storeQueryParams: {
    pageNumber: pageParams.pageNumber,
    rowsPerPage: pageParams.rowsPerPage
  },
};
const slice = createSlice({
  name: 'populationDistribution',
  initialState,
  reducers: {
    setQueryParams(state, action) {
      Object.assign(state.storeQueryParams = action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

export function setStorageValues(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.setQueryParams(queryParams));
  };
}
