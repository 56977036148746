export const gender = {
    list: {
        title: `Սեռ`,
    },
    create: {
        title: `Ստեղծել սեռ`,
    },
    update: {
        title: `Խմբագրել սեռը`,
    },
    breadcrumbs: {
        base: `Սեռ`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
    },
    fields: {
        name: `անվանում`,
        code: `կոդ`,
    },
    messages: {
        nameismandatory: `անվանումը պարտադիր է`,
        codeismandatory: `կոդը պարտադիր է`,
    }
};