export const general = {
    actions: {
        cancel: `Cancel`,
        create: `Create`,
        save: `Save changes`,
        duplicate: `Duplicate`,
        update: `Edit`,
        delete: `Delete`,
        search: `Search`,
        clear: `Clear`,
        loading: `Loading...`,
        logout: `Logout`,
        add: `Add`,
        cost: `New cost`,
        upload: `Upload`,
        import: `Import`,
        attach_file: `Attach file`,
        combine: `combine`
    },
    messages:{
        deleteConfirm: `Are you sure to delete the record?`,
        deleteFileConfirm: `Are you sure to delete the file?`,
        importFileConfirm: `Are you sure to import the file?`,
        importStarted: `Import has started`,
        importSuccess: `successfully imported`,
        importError: `failed to import`,
        getDataError: `failed to get data`,
        createSuccess: `successfully created`,
        createError: `failed to create`,
        updateSuccess: `successfully created`,
        updateError: `failed to create`,
        deleteSuccess: `successfully deleted`,
        deleteError: `failed to delete`,
        addError: `failed to add`,
        addSuccess: `successfully added`,
        costSuccess: `new cost added successfully`,
        costError: `failed to add new cost`,
        combineSuccess: `successfully combined`,
        combineEroor: `failed to combine`,
        uploadSuccess: `successfully uploaded`,
        uploadError: `failed to upload`,
        serverError: `An error occurred. The operation failed.`,
        probabilityError: `the probability must be in the range from 0 to 1`,
        fieldIsRequired: `The field is required`,
        xlsFileFormatError: `The file format must be .xlsx or .xls`
    },
    notices: {
        typeToSearch: `Type to search...`,
        none: `none`,
        all: `all`
    },
    demo: {
        title: `English`,
        introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    },
    docs: {
        hi: `Hi`,
        description: `Need help? \n Please check our docs.`,
        documentation: `documentation`,
    },
    complicationTypes: {
        wComplications: `With complications`,
        woComplications: `Without complications`
    },
    specificationTypes: {
        specific: `Specific`,
        nonSpecific: `Non specific`
    },
    treatmentStateTypes: {
        duringDiagnosis: `During diagnosis`,
        duringTreatment: `During treatment`
    },
    fileImportStatuses: {
        uploaded: `uploaded`,
        importInProgress: `import in progress`,
        imported: `imported`,
        importFailed: `import failed`,
    },
    searchTypes: {
        all: `All`,
        byDate: `By date`
    },
    servicePackSpecsTabs: {
        dataExecution: `data execution`,
        costCalculaion: `summary`,
        consultationCode: `code`,
        consultationName: `consultation name`,
        probability: `probability`,
        quantity: `quantity`,
        dayQuantity: `average daily dose`,
        treatmentQuantity: `average treatment dose`,
        price: `price`,
        lineTotalPrice: `total`,
    },
    nonCountCoefficients: {
        therapyServices: `Therapy services (0.5)`,
        surgeryServices: `Surgery services (0.1)`,
    },
    organizationTypeCoefficients: {
        ruralHealthCenter: `Rural health center (0.3)`,
        regionalHealthCenter: `Regional health center (0.4)`,
        specializedHealthCenter: `Specialized health center (0.5)`,
    },
    dense: `dense`,
    no_data: `no data`,
    app: `app`,
    user: `user`,
    list: `list`,
    edit: `edit`,
    shop: `shop`,
    blog: `blog`,
    post: `post`,
    mail: `mail`,
    chat: `chat`,
    cards: `cards`,
    posts: `posts`,
    create: `create`,
    kanban: `kanban`,
    general: `general`,
    banking: `banking`,
    booking: `booking`,
    profile: `profile`,
    account: `account`,
    product: `product`,
    invoice: `invoice`,
    details: `details`,
    checkout: `checkout`,
    calendar: `calendar`,
    analytics: `analytics`,
    ecommerce: `e-commerce`,
    management: `management`,
    menu_level: `menu level`,
    menu_level_2a: `menu level 2a`,
    menu_level_2b: `menu level 2b`,
    menu_level_3a: `menu level 3a`,
    menu_level_3b: `menu level 3b`,
    menu_level_4a: `menu level 4a`,
    menu_level_4b: `menu level 4b`,
    item_disabled: `item disabled`,
    item_label: `item label`,
    item_caption: `item caption`,
    item_external_link: `item external link`,
    description: `description`,
    other_cases: `other cases`,
    item_by_roles: `item by roles`,
    only_admin_can_see_this_item: `Only admin can see this item`,
};