import { general } from './armenian/general'
import { menu } from './armenian/menu'

import { front_page } from "./armenian/front-page";
import { dashboardHomePage } from "./armenian/dashboardHomePage";

import { serviceFile } from "./armenian/serviceFile"
import { healthCase } from "./armenian/healthCase"

import { actuarialType } from "./armenian/actuarialType";
import { ageGroup } from "./armenian/ageGroup";
import { gender } from "./armenian/gender";
import { healthServiceGroup } from "./armenian/healthServiceGroup";
import { populationGroup } from "./armenian/populationGroup";
import { populationDistribution } from "./armenian/populationDistribution";
import { combinationService } from "./armenian/combinationService";
import { combinationSocGroup } from "./armenian/combinationSocGroup";

const hy = {
    general,
    menu,
    serviceFile,
    healthCase,
    actuarialType,
    ageGroup,
    gender,
    healthServiceGroup,
    populationGroup,
    front_page,
    dashboardHomePage,
    populationDistribution,
    combinationService,
    combinationSocGroup
};

export default hy;