export const actuarialType = {
    list: {
        title: `Ակտուարային տեսակներ`,
    },
    create: {
        title: `Ստեղծել ակտուարային տեսակ`,
    },
    update: {
        title: `Խմբագրել ակտուարային տեսակը`,
    },
    breadcrumbs: {
        base: `Ակտուարային տեսակներ`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
    },
    fields: {
        name: `անվանում`,
        code: `կոդ`,
    },
    messages: {
        nameismandatory: `անվանումը պարտադիր է`,
        codeismandatory: `կոդը պարտադիր է`,
    }
};