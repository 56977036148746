export const front_page = {
    title: `Healthcare Insurance Package Calculator`,
    title_abbr: `(HIPC)`,
    menu: {
        dashboard: `Dashboard`,
        documentation: `Documentation`,
        calculator: `Package Calculator`
    },
    card1: {
        title: ``,
        body: ``
    },
    card2: {
        title: ``,
        body: ``
    },
    card3: {
        title: ``,
        body: ``
    },
    footer: {
        copyright: 'All rights reserved',
        made_by: 'made by'
    }
};