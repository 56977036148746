export const populationGroup = {
    list: {
        title: `Բնակչության խմբեր`,
    },
    create: {
        title: `Ստեղծել բնակչության խումբ`,
    },
    update: {
        title: `Խմբագրել բնակչության խումբը`,
    },
    breadcrumbs: {
        base: `Բնակչության խմբեր`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
    },
    fields: {
        name: `անվանում`,
        code: `կոդ`,
    },
    messages: {
        nameismandatory: `անվանումը պարտադիր է`,
        codeismandatory: `կոդը պարտադիր է`,
    }
};