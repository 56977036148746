export const ageGroup = {
    list: {
        title: `Age Groups`,
    },
    create: {
        title: `Create age group`,
    },
    update: {
        title: `Update age group`,
    },
    breadcrumbs: {
        base: `Age Groups`,
        create: `Create`,
        update: `Update`,
    },
    fields: {
        name: `name`,
        code: `code`,
    },
    messages: {
        nameismandatory: `name is mandatory`,
        codeismandatory: `code is mandatory`,
    }
};