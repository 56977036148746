import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// auth
import { AuthProvider } from "react-oidc-context";

import { oidcConfig } from '../config-global'

OAuthProvider.propTypes = {
    children: PropTypes.node,
};

function OAuthProvider({ children }) {
    const { i18n } = useTranslation();
    const config = { ...oidcConfig, extraQueryParams: { culture: i18n.language } }
    const OAuthProviderComponent = memo(() => <AuthProvider {...config}>{children}</AuthProvider>)

    return <OAuthProviderComponent />
}

export { OAuthProvider };