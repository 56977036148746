export const dashboardHomePage = {
    title: `Home page`,
    welcome: `Welcome`,
    welcome_note: `You are in the environment of Healthcare Insurance Package Calculator`,
    slider1: {
        title: `Catalogs`,
        description: `Necessary catalogs are being developed`
    },
    slider2: {
        title: `Service passports`,
            description: `The service costs are calculated`
    },
    catalogs_info: {
        count: `Catalogs count`
    },
    services_info: {
        count: `Service count`
    },
    test_info: {
        count: `Any value`
    }
};