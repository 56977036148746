export const menu = {
    general: `General`,
    main: `Main`,
    dashboard: `Dashboard`,
    serviceFiles: `Service Files`,
    healthCases: `Health Cases`,
    combination: `combination`,
    combinationServices: `services`,
    combinationSocGroups: `social groups`,
    catalogs: `Catalogs`,
    actuarialTypes: `Actuarial Types`,
    ageGroups: `Age Groups`,
    genders: `Genders`,
    healthServiceGroups: `Health Service Groups`,
    populationGroups: `Population Groups`,
    populationDistributions: `Population Distributions`
};