export const combinationSocGroup = {
    list: {
        title: `Combination of Social Group`,
    },
    // create: {
    //     title: `Create service`,
    // },
    update: {
        title: `Update service`,
    },
    // cost: {
    //     title: `New service cost`,
    // },
    breadcrumbs: {
        base: `Combination of Social Group`,
        // create: `Create`,
        update: `Update`,
        // cost: `Cost`
    },
    fields: {
        distinctPopulationName: `social group name`,
        matchedPopulationName: `matched social group`,
        matchedPopulationId: `Id`,
    },
    messages: {
        getCurrentItemError: `Failed to get selected service`,
        getPopulationDicError: `Failed to get population group dictionary`
        // getGroupDicError: `Failed to get group dictionary`,
        // getTypeDicError: `Failed to get type dictionary`,
        // nameismandatory: `name is mandatory`,
        // codeismandatory: `code is mandatory`,
        // groupismandatory: `group is mandatory`,
        // typeismandatory: `type is mandatory`,
        // unitpriceismandatory: `unitprice is mandatory`,
        // startdateismandatory: `star tdate is mandatory`,
        // finaldateismandatory: `final date is mandatory`
    }
};