export const actuarialType = {
    list: {
        title: `Actuarial types`,
    },
    create: {
        title: `Create actuarial type`,
    },
    update: {
        title: `Update actuarial type`,
    },
    breadcrumbs: {
        base: `Actuarial types`,
        create: `Create`,
        update: `Update`,
    },
    fields: {
        name: `name`,
        code: `code`,
    },
    messages: {
        nameismandatory: `name is mandatory`,
        codeismandatory: `code is mandatory`,
    }
};