export const front_page = {
    title: `Առողջապահական Ապահովագրության Ծածկույթի Հաշվարկման Գործիք`,
    title_abbr: `(ԱԱԾՀԳ)`,
    menu: {
        dashboard: `Գործիք`,
        documentation: `Փաստաթղթեր`,
        calculator: `Հաշվարկման Գործիք`
    },
    card1: {
        title: `Նպատակը`,
        body: `Պետության կողմից փոխհատուցվող բժշկական օգնության և սպասարկման ծառայությունների գների հաշվարկում ավտոմատացված եղանակով։`
    },
    card2: {
        title: `Մշակումը`,
        body: `Սույն գործիքը մշակվել է ԱԶԲ տեխնիկական օժանդակությամբ և «Հայաստանի Հանրապետությունում առողջապահական համակարգի բարեփոխումների տեխնիկական աջակցության» դրամաշնորհային ծրագրի «Առողջապահության համապարփակ ապահովագրության ներդրման տեխնիկական աջակցության» բաղադրիչի շրջանակներում։`
    },
    card3: {
        title: `Հասանելիություն`,
        body: `Գործիքը հասանելի է միայն համապատասխան լիազորություններ ունեցող օգտատերերի համար։`
    },
    footer: {
        copyright: 'Բոլոր իրավունքները պաշտպանված են',
        made_by: 'մշակող'
    }
};