export const combinationSocGroup = {
    list: {
        title: `Սոցիալական խմբերի համադրում`,
    },
    // create: {
    //     title: `Ստեղծել ծառայություն`,
    // },
    update: {
        title: `Խմբագրել ծառայությունը`,
    },
    // cost: {
    //     title: `Ծառայության նոր արժեք`,
    // },
    breadcrumbs: {
        base: `Սոցիալական խմբերի համադրում`,
        // create: `Ստեղծել`,
        update: `Խմբագրել`,
        // cost: `Արժեք`
    },
    fields: {
        distinctPopulationName: `Սոց․ խմբի ավանումը`,
        matchedPopulationName: `համադրված`,
        matchedPopulationId: `Id`,
    },
    messages: {
        getCurrentItemError: `Չհաջողվեց ստանալ նշված ծառայության տվյալները`,
        getPopulationDicError: `Չհաջողվեց ստանալ բնակչության խմբերի բառարանը`
        // getGroupDicError: `Չհաջողվեց ստանալ խմբերի բառարանը`,
        // getTypeDicError: `Չհաջողվեց ստանալ տեսակների բառարանը`,
        // nameismandatory: `անվանումը պարտադիր է`,
        // codeismandatory: `կոդը պարտադիր է`,
        // groupismandatory: `խումբը պարտադիր է`,
        // typeismandatory: `տեսակը պարտադիր է`,
        // unitpriceismandatory: `միավոր արժեքը պարտադիր է`,
        // startdateismandatory: `սկզբի ամսաթիվը պարտադիր է`,
        // finaldateismandatory: `ավարտի ամսաթիվը պարտադիր է`
    }
};