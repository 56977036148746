import PropTypes from 'prop-types';
// import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// localization
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

HeaderLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function HeaderLogo({ disabledLink = false, sx }) {

  const { currentLang } = useLocales();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  const logo = (
    <Box sx={{ ...sx, display: 'inline-block', }}>
      {currentLang.value === 'en' && <img
        height={80}
        src="/logo/image_header.svg"
        alt="logo"
        loading="lazy"
      />}
      {currentLang.value === 'hy' && <img
        height={80}
        src="/logo/image_header_am.svg"
        alt="logo"
        loading="lazy"
      />}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
