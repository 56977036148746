export const healthServiceGroup = {
    list: {
        title: `Ծառայության խմբեր`,
    },
    create: {
        title: `Ստեղծել ծառայության խումբ`,
    },
    update: {
        title: `Խմբագրել ծառայության խումբը`,
    },
    breadcrumbs: {
        base: `Ծառայության խմբեր`,
        create: `Ստեղծել`,
        update: `Խմբագրել`,
    },
    fields: {
        name: `անվանում`,
        code: `կոդ`,
    },
    messages: {
        nameismandatory: `անվանումը պարտադիր է`,
        codeismandatory: `կոդը պարտադիր է`,
    }
};