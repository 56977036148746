export const serviceFile = {
    list: {
        title: `Service files`,
    },
    create: {
        title: `Upload file`,
    },
    update: {
        title: `Update description`,
    },
    breadcrumbs: {
        base: `Service files`,
        create: `Upload`,
        update: `Update`,
    },
    fields: {
        name: `name`,
        description: `description`,
        importStatus: `status`,
        lastImportDate: `last import date`,
    },
    messages: {
        getCurrentItemError: `Failed to get selected file`,
        fileismandatory: `file is mandatory`,
    }
};