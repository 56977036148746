export const combinationService = {
    list: {
        title: `Combination of Services`,
    },
    // create: {
    //     title: `Create service`,
    // },
    update: {
        title: `Update service`,
    },
    // cost: {
    //     title: `New service cost`,
    // },
    breadcrumbs: {
        base: `Combination of Services`,
        // create: `Create`,
        update: `Update`,
        // cost: `Cost`
    },
    fields: {
        distinctServiceName: `service name`,
        matchedServiceName: `matched service`,
        matchedServiceId: `Id`,
    },
    messages: {
        getCurrentItemError: `Failed to get selected service`,
        getServiceDicErrorMessage: `Failed to get service dictionary`
        // getGroupDicError: `Failed to get group dictionary`,
        // getTypeDicError: `Failed to get type dictionary`,
        // nameismandatory: `name is mandatory`,
        // codeismandatory: `code is mandatory`,
        // groupismandatory: `group is mandatory`,
        // typeismandatory: `type is mandatory`,
        // unitpriceismandatory: `unitprice is mandatory`,
        // startdateismandatory: `star tdate is mandatory`,
        // finaldateismandatory: `final date is mandatory`
    }
};