import { Suspense, lazy } from 'react';
// components
// import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense 
  // fallback={<LoadingScreen />}
  >
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
// export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
// export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
// export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
// export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
// export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));


// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
// export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
// export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
// export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
// export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
// export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));


// DASHBOARD: MAIN
// // ServiceFiles
export const ServiceFileListPage = Loadable(
  lazy(() => import('../pages/dashboard/Main/serviceFiles/ServiceFileListPage'))
);
export const ServiceFileCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Main/serviceFiles/ServiceFileCreatePage'))
);
export const ServiceFileUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Main/serviceFiles/ServiceFileUpdatePage'))
);

// // ServiceFiles
export const HealthCaseListPage = Loadable(
  lazy(() => import('../pages/dashboard/Main/healthCases/HealthCaseListPage'))
);
// export const HealthCaseCreatePage = Loadable(
//   lazy(() => import('../pages/dashboard/Main/healthCases/HealthCaseCreatePage'))
// );
// export const HealthCaseUpdatePage = Loadable(
//   lazy(() => import('../pages/dashboard/Main/healthCases/HealthCaseUpdatePage'))
// );

// // PopulationDistributions
export const PopulationDistributionListPage = Loadable(
  lazy(() => import('../pages/dashboard/Main/populationDistributions/PopulationDistributionListPage'))
);
export const PopulationDistributionCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Main/populationDistributions/PopulationDistributionCreatePage'))
);
export const PopulationDistributionUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Main/populationDistributions/PopulationDistributionUpdatePage'))
);
export const PopulationDistributionImportPage = Loadable(
  lazy(() => import('../pages/dashboard/Main/populationDistributions/PopulationDistributionImportPage'))
);
// export const HealthCaseCreatePage = Loadable(
//   lazy(() => import('../pages/dashboard/Main/healthCases/HealthCaseCreatePage'))
// );
// export const HealthCaseUpdatePage = Loadable(
//   lazy(() => import('../pages/dashboard/Main/healthCases/HealthCaseUpdatePage'))
// );

// // CombinationService
export const CombinationServiceListPage = Loadable(
  lazy(() => import('../pages/dashboard/Main/combinationServices/CombinationServiceListPage'))
);


// // CombinationService
export const CombinationSocGroupListPage = Loadable(
  lazy(() => import('../pages/dashboard/Main/combinationSocGroups/CombinationSocGroupListPage'))
);



// DASHBOARD: CATALOGS
// // ActuarialTypes
export const ActuarialTypeListPage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/ActuarialTypes/ActuarialTypeListPage'))
);

export const ActuarialTypeCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/ActuarialTypes/ActuarialTypeCreatePage'))
);

export const ActuarialTypeUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/ActuarialTypes/ActuarialTypeUpdatePage'))
);


// // AgeGroups
export const AgeGroupListPage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/AgeGroups/AgeGroupListPage'))
);

export const AgeGroupCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/AgeGroups/AgeGroupCreatePage'))
);

export const AgeGroupUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/AgeGroups/AgeGroupUpdatePage'))
);

// // Genders
export const GenderListPage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/Genders/GenderListPage'))
);

export const GenderCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/Genders/GenderCreatePage'))
);

export const GenderUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/Genders/GenderUpdatePage'))
);


// // HealthServiceGroups
export const HealthServiceGroupListPage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/HealthServiceGroups/HealthServiceGroupListPage'))
);
export const HealthServiceGroupCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/HealthServiceGroups/HealthServiceGroupCreatePage'))
);
export const HealthServiceGroupUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/HealthServiceGroups/HealthServiceGroupUpdatePage'))
);

// // PopulationGroups
export const PopulationGroupListPage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/PopulationGroups/PopulationGroupListPage'))
);
export const PopulationGroupCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/PopulationGroups/PopulationGroupCreatePage'))
);
export const PopulationGroupUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/Catalogs/PopulationGroups/PopulationGroupUpdatePage'))
);
