// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

// Custom mage URL not to override the existing URLs in th sample
// export const HOST_API_URL = process.env.REACT_APP_HOST_API_URL || '';

// resource:remote
export const HOST_API_URL = 'https://api.dvcp-services.org/'

// -- old configuration --
// export const HOST_API_URL = 'https://api.dvcp-apps.org/'

// // resource:local
// export const HOST_API_URL = 'https://localhost:44315/'

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// front:local & oauth:local


// // front:local & oauth:remote
// export const oidcConfig = {
//   authority: 'https://idn.dvcp-services.org',
//   client_id: 'ipct_local_client',
//   redirect_uri: 'https://localhost:4300/dashboard',
//   post_logout_redirect_uri: 'https://localhost:4300',
//   scope: 'openid profile roles phone resourceServerScope',
// };

// front:remote & oauth:remote
export const oidcConfig = {
  authority: 'https://idn.dvcp-services.org',
  client_id: 'ipct_online_apps_uhif_am_client',
  redirect_uri: 'https://ipct.online-apps.uhif.am/dashboard',
  post_logout_redirect_uri: 'https://ipct.online-apps.uhif.am',
  scope: 'openid profile roles phone resourceServerScope',
 };

// -- old configuration --
// export const oidcConfig = {
//  authority: 'https://oauth.dvcp-apps.org',
//  client_id: 'ipct_production_client',
//  redirect_uri: 'https://ipct.dvcp-apps.org/dashboard',
//  post_logout_redirect_uri: 'https://ipct.dvcp-apps.org',
//  scope: 'openid profile roles phone resourceServerScope',
// };


// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// Page Parameters
// ----------------------------------------------------------------------
export const pageParams = {
  pageNumber: 0,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100, 500]
};
