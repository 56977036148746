export const menu = {
    general: `Հիմնական`,
    main: `Հիմնական`,
    dashboard: `Վահանակ`,
    serviceFiles: `Ծառայությունների ֆայլեր`,
    healthCases: `Փաստացի դեպքեր`,
    combination: `համադրում`,
    combinationServices: `Ծառայություններ`,
    combinationSocGroups: `Սոց․ խմբեր`,
    catalogs: `Շտեմարաններ`,
    actuarialTypes: `Ակտուարային տեսակներ`,
    ageGroups: `Տարիքային խմբեր`,
    genders: `Սեռ`,
    healthServiceGroups: `Ծառայության խմբեր`,
    populationGroups: `Բնակչության խմբեր`,
    populationDistributions: `Բնակչության բաշխում`
};