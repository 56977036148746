export const healthCase = {
    list: {
        title: `Health Cases`,
    },
    create: {
        title: `Create a case`,
    },
    update: {
        title: `Update case`,
    },
    breadcrumbs: {
        base: `Health Cases`,
        create: `Create`,
        update: `Update`,
    },
    fields: {
        caseNumber: `number`,
        identificator: `identificator`,
        birthDate: `birth date`,
        gender: `gender`,
        dataOperator: `operator`,
        service: `service`,
        serviceStartDate: `start date`,
        serviceCompletionDate: `completion date`,
    },
    messages: {
        getCurrentItemError: `Failed to get selected case`,
        caseNumberismandatory: `case number is mandatory`,
    }
};