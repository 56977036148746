export const combinationService = {
    list: {
        title: `Ծառայությունների համադրում`,
    },
    // create: {
    //     title: `Ստեղծել ծառայություն`,
    // },
    update: {
        title: `Խմբագրել ծառայությունը`,
    },
    // cost: {
    //     title: `Ծառայության նոր արժեք`,
    // },
    breadcrumbs: {
        base: `Ծառայությունների համադրում`,
        // create: `Ստեղծել`,
        update: `Խմբագրել`,
        // cost: `Արժեք`
    },
    fields: {
        distinctServiceName: `ծառայության ավանումը`,
        matchedServiceName: `համադրված`,
        matchedServiceId: `Id`,
    },
    messages: {
        getCurrentItemError: `Չհաջողվեց ստանալ նշված ծառայության տվյալները`,
        getServiceDicErrorMessage: `Չհաջողվեց ստանալ ծառայությունների բառարանը`
        // getGroupDicError: `Չհաջողվեց ստանալ խմբերի բառարանը`,
        // getTypeDicError: `Չհաջողվեց ստանալ տեսակների բառարանը`,
        // nameismandatory: `անվանումը պարտադիր է`,
        // codeismandatory: `կոդը պարտադիր է`,
        // groupismandatory: `խումբը պարտադիր է`,
        // typeismandatory: `տեսակը պարտադիր է`,
        // unitpriceismandatory: `միավոր արժեքը պարտադիր է`,
        // startdateismandatory: `սկզբի ամսաթիվը պարտադիր է`,
        // finaldateismandatory: `ավարտի ամսաթիվը պարտադիր է`
    }
};