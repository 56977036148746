
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';



// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  // -----------------------------
  exam: icon('ic_exam'),
  drug: icon('ic_drug'),
  diagnose: icon('ic_diagnose'),
  healthcare: icon('ic_healthcare'),
  examination: icon('ic_examination'),
  consumable: icon('ic_consumable'),
  surgery: icon('ic_surgery'),
  otherService: icon('ic_otherService'),
  diet: icon('ic_diet'),
  organizations: icon('ic_organizations'),
  specializations: icon('ic_specializations'),
  consultations: icon('ic_receipt'),
  servicePackages: icon('ic_calculator'),
};

const navConfig = [
  // DASHBOARD
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'menu.dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      }
    ],
  },
  {
    subheader: 'menu.main',
    items: [
      {
        title: 'menu.serviceFiles',
        path: PATH_DASHBOARD.serviceFiles.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.healthCases',
        path: PATH_DASHBOARD.healthCases.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.populationDistributions',
        path: PATH_DASHBOARD.populationDistributions.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.combination',
        path: PATH_DASHBOARD.combination.root,
        icon: ICONS.menuItem,
        children: [
          { title: 'menu.combinationServices', path: PATH_DASHBOARD.combinationServices.list },
          { title: 'menu.combinationSocGroups', path: PATH_DASHBOARD.combinationSocGroups.list },
        ],
      },
    ],
  },
  // CATALOGS
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.catalogs',
    items: [
      // {
      //   title: 'menu.services',
      //   path: PATH_DASHBOARD.services.root,
      //   icon: ICONS.healthcare,
      //   children: [
      //     { title: 'menu.services', path: PATH_DASHBOARD.services.list },
      //     { title: 'menu.serviceGroups', path: PATH_DASHBOARD.serviceGroups.list },
      //     { title: 'menu.serviceTypes', path: PATH_DASHBOARD.serviceTypes.list },
      //   ],
      // },
      {
        title: 'menu.actuarialTypes',
        path: PATH_DASHBOARD.actuarialTypes.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.ageGroups',
        path: PATH_DASHBOARD.ageGroups.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.genders',
        path: PATH_DASHBOARD.genders.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.healthServiceGroups',
        path: PATH_DASHBOARD.healthServiceGroups.list,
        icon: ICONS.menuItem,
      },
      {
        title: 'menu.populationGroups',
        path: PATH_DASHBOARD.populationGroups.list,
        icon: ICONS.menuItem,
      },
    ],
  },
  // // SAMPLES
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'samples',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //         { title: 'comingSoon', path: PATH_PAGE.comingSoon },
  //         { title: 'maintenance', path: PATH_PAGE.maintenance },
  //         { title: 'pricing', path: PATH_PAGE.pricing },
  //         { title: 'payment', path: PATH_PAGE.payment },
  //         { title: 'about', path: PATH_PAGE.about },
  //         { title: 'contact', path: PATH_PAGE.contact },
  //         { title: 'faqs', path: PATH_PAGE.faqs },
  //         { title: 'page403', path: PATH_PAGE.page403 },
  //         { title: 'page404', path: PATH_PAGE.page404 },
  //         { title: 'page500', path: PATH_PAGE.page500 },
  //         { title: 'components', path: PATH_PAGE.components },
  //       ],
  //     },
  //     // E-COMMERCE
  //     {
  //       title: 'ecommerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },
  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },
  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //     {
  //       title: 'File manager',
  //       path: PATH_DASHBOARD.fileManager,
  //       icon: ICONS.folder,
  //     },
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //     {
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },  
];

export default navConfig;
