export const general = {
    actions: {
        cancel: `Չեղարկել`,
        create: `Ստեղծել`,
        save: `Հիշել փոփոխությունները`,
        duplicate: `Կրկնօրինակել`,
        update:`Խմբագրել`,
        delete: `Հեռացնել`,
        search: `Որոնել`,
        clear: `Մաքրել`,
        loading: `Բեռնվում է․․․`,
        logout: `Ելք`,
        add: `Ավելացնել`,
        cost: `Նոր արժեք`,
        upload: `Վերբեռնել`,
        import: `Ներմուծել`,
        attach_file: `Կցել ֆայլ`,
        combine: `համադրել`
    },
    messages:{
        deleteConfirm: `Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել գրառումը։`,
        deleteFileConfirm: `Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել ֆայլը`,
        importFileConfirm: `Համոզվա՞ծ եք, որ ցանկանում եք ներմուծել ֆայլը`,
        importStarted: `Ներմուծումը սկսված է`,
        importSuccess: `Հաջողությամբ ներմուծվեց`,
        importError: `Չհաջողվեց ներմուծել`,
        getDataError: `Չհաջողվեց ստանալ տվյալներ`,
        createSuccess: `Հաջողությմաբ ստեղծվեց`,
        createError: `Չհաջողվեց ստեղծել`,
        updateSuccess: `Հաջողությմաբ խմբագրվեց`,
        updateError: `Չհաջողվեց խմբագրել`,
        deleteSuccess: `Հաջողությմաբ հեռացվեց`,
        deleteError: `Չհաջողվեց հեռացնել`,
        addError: `Չհաջողվեց ավելացնել`,
        addSuccess: `Հաջողությմաբ ավելացվեց`,
        costSuccess: `Նոր արժեքը հաջողությամբ ավելավցվեց`,
        costError: `Չհաջողվեց ավելացնել նոր արժեքը`,
        uploadSuccess: `Հաջողությմաբ վերբեռնվեց`,
        uploadError: `Չհաջողվեց վերբեռնել`,
        serverError: `Տեղի է ունեցել սխալ։ Գործողությունը չկատարվեց։`,
        combineSuccess: `Հաջողությամբ համադրվեց`,
        combineEroor: `Չհաջողվեց համադրել`,
        probabilityError: `հավանականությունը պետք է լինի 0-ից 1 միջակայքում`,
        fieldIsRequired: `Դաշտը պարտադիր է`,
        xlsFileFormatError: `Ֆայլի ֆորմատը պետք է լինի .xlsx կամ .xls`
    },
    notices: {
        typeToSearch: `որոնել...`,
        none: `ոչ մեկը`,
        all: `բոլորը`
    },
    demo: {
        title: `English`,
        introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    },
    docs: {
        hi: `Hi`,
        description: `Need help? \n Please check our docs.`,
        documentation: `Փաստաթղթեր`,
    },
    complicationTypes: {
        wComplications: `Բարդություններով`,
        woComplications: `Առանց բարդությունների`
    },
    specificationTypes: {
        specific: `Սպեցիֆիկ`,
        nonSpecific: `Ոչ Սպեցիֆիկ`
    },
    treatmentStateTypes: {
        duringDiagnosis: `Ախտորոշման ժամանակ`,
        duringTreatment: `Հիվանդության ժամանակ`
    },
    fileImportStatuses: {
        uploaded: `վերբեռնված`,
        importInProgress: `ներմուծումը ընթանում է`,
        imported: `ներմուծված`,
        importFailed: `ներմուծումը չի հաջողվել`,
    },
    searchTypes: {
        all: `Բոլորը`,
        byDate: `Ամսաթվով`
    },
    servicePackSpecsTabs: {
        dataExecution: `տվյալների մշակում`,
        costCalculaion: `ամփոփագիր`,
        consultationCode: `կոդ`,
        consultationName: `խորհրդակցության անվանում`,
        probability: `հավանական․`,
        quantity: `քանակ`,
        dayQuantity: `միջին օրական չափաքանակ`,
        treatmentQuantity: `բուժման կուրսի միջին չափաքանակ`,
        price: `գին`,
        lineTotalPrice: `ընդհամենը`,
    },
    nonCountCoefficients: {
        therapyServices: `Թերապևտիկ պրոֆիլի ծառայություններ (0.5)`,
        surgeryServices: `Վիրաբուժական բնույթի ծառայություններ (0.1)`,
    },
    organizationTypeCoefficients: {
        ruralHealthCenter: `Գյուղական առողջության կենտրոն (0.3)`,
        regionalHealthCenter: `Տարածաշրջանային կենտրոն (0.4)`,
        specializedHealthCenter: `Մասնագիտացված կենտրոն (0.5)`,
    },
    dense: `խիտ`,
    no_data: `տվյալներ չկան`,
    app: `app`,
    user: `user`,
    list: `list`,
    edit: `edit`,
    shop: `shop`,
    blog: `blog`,
    post: `post`,
    mail: `mail`,
    chat: `chat`,
    cards: `cards`,
    posts: `posts`,
    create: `create`,
    kanban: `kanban`,
    general: `general`,
    banking: `banking`,
    booking: `booking`,
    profile: `profile`,
    account: `account`,
    product: `product`,
    invoice: `invoice`,
    details: `details`,
    checkout: `checkout`,
    calendar: `calendar`,
    analytics: `analytics`,
    ecommerce: `e-commerce`,
    management: `management`,
    menu_level: `menu level`,
    menu_level_2a: `menu level 2a`,
    menu_level_2b: `menu level 2b`,
    menu_level_3a: `menu level 3a`,
    menu_level_3b: `menu level 3b`,
    menu_level_4a: `menu level 4a`,
    menu_level_4b: `menu level 4b`,
    item_disabled: `item disabled`,
    item_label: `item label`,
    item_caption: `item caption`,
    item_external_link: `item external link`,
    description: `description`,
    other_cases: `other cases`,
    item_by_roles: `item by roles`,
    only_admin_can_see_this_item: `Only admin can see this item`,
};