export const populationDistribution = {
    list: {
        title: `Population distribution`,
    },
    create: {
        title: `Create group`,
    },
    update: {
        title: `Update group`,
    },
    import: {
        title: `Import groups`,
    },
    breadcrumbs: {
        base: `Population distribution`,
        create: `Create`,
        update: `Update`,
        import: `Import`,
    },
    fields: {
        genderName: `gender`,
        ageGroupName: `age group`,
        populationGroupName: `population group`,
        population: `population`,
        year: `year`,
        removeBeforeImport: `remove before import`
    },
    groups: {
        code01: `Empolyees and self-employed`,
        code02: `People with disabilities`,
        code03: `Children`,
        code04: `Other`,
        code05: `Retirees (Persons aged 63+)`,
        code06: `Socially vulnerable and Special Groups (Including Military Family Members)`
    },
    tabs: {
        dataExecution: `data execution`,
        summary: `summary`
    },
    messages: {
        getCurrentItemError: `Failed to get selected group`,
        genderIsRequired: `gender is mandatory`,
        ageGroupIsRequired: `age group is mandatory`,
        populationGroupIsRequired: `population group is mandatory`,
        populationIsRequired: `population is mandatory`,
        yearIsRequired: `year is mandatory`,
        getGengerDicError: `Failed to get gender dictionary`,
        getAgeGroupDicError: `Failed to get age group dictionary`,
        getPopulationGroupDicError: `Failed to get population group dictionary`,
        fileismandatory: `file is mandatory`,
    }
};