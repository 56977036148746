export const serviceFile = {
    list: {
        title: `Ծառայությունների  ֆայլեր`,
    },
    create: {
        title: `Վերբեռնել ֆայլ`,
    },
    update: {
        title: `Խմբագրել ֆայլի նկարագրությունը`,
    },
    breadcrumbs: {
        base: `Ծառայությունների  ֆայլեր`,
        create: `Վերբեռնել`,
        update: `Խմբագրել`,
    },
    fields: {
        name: `անվանում`,
        description: `նկարագրություն`,
        importStatus: `կարգավիճակ`,
        lastImportDate: `վերջին ներմուծման ա/թ`,
    },
    messages: {
        getCurrentItemError: `Չհաջողվեց ստանալ նշված ֆայլի տվյալները`,
        fileismandatory: `ֆայլը պարտադիր է`,
    }
};